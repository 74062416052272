import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

const store = createStore({
  plugins: [createPersistedState()],
  state: {
    tabVmodel: 0,
    EmailID: "",
    getPreviousRoute: "",
    themeLoaded: false,
    projectDetails: {},
    themeDetailsObj: {},
    getIssueDetails: {},
    currentUserDetails: {},
    tabsArray: [],
    projectStagesSubStagesAndSteps: [],
  },
  getters: {
    get_user_email: (state) => state.EmailID,
    get_current_user_details: (state) => state.currentUserDetails,
    get_current_project_details: (state) => state.projectDetails,
    get_tabs_array: (state) => state.tabsArray,
    get_current_active_tab: (state) => state.tabVmodel,
    get_theme_details: (state) => state.themeDetailsObj,
    get_theme_loaded_bool: (state) => state.themeLoaded,
    get_inside_project_array: (state) => state.projectStagesSubStagesAndSteps,
  },
  mutations: {
    SET_USEREMAIL(state, username) {
      state.EmailID = username;
    },
    SET_CURRENTUSER_DETAILS(state, user) {
      state.currentUserDetails = user;
    },
    SET_PROJECT_DETAILS(state, projectDetails) {
      state.projectDetails = projectDetails;
    },
    SET_TABS_ARRAY(state, tabsArray) {
      state.tabsArray = tabsArray;
    },
    SET_TAB_VMODEL(state, tabVmodel) {
      state.tabVmodel = tabVmodel;
    },
    SET_THEME_DETAILS(state, themeDetailsObj) {
      state.themeDetailsObj = themeDetailsObj;
    },
    SET_THEME_LOADED(state, themeLoaded) {
      state.themeLoaded = themeLoaded;
    },
    SET_INSIDE_PROJECT_ARRAY(state, projectStagesSubStagesAndSteps) {
      state.projectStagesSubStagesAndSteps = projectStagesSubStagesAndSteps;
    },
  },
  actions: {},
  modules: {},
});

export default store;
