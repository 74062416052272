import { createRouter, createWebHistory } from "vue-router";

import LoginPage from "../views/MainHomePages/LoginPage.vue";
const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    component: () => import(/* webpackChunkName: "Landing Page" */ "../views/MainHomePages/LandingPage.vue"),
    children: [
      {
        path: "/QueryManagement",
        name: "QueryManagement",
        component: () => import(/* webpackChunkName: "QueryManagement" */ "../views/ChildHomePages/QueryManagement.vue"),
      },
      {
        path: "/Notifications",
        name: "Notifications",
        component: () => import(/* webpackChunkName: "Notifications" */ "../views/ChildHomePages/Notifications.vue"),
      },
    ],
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
